/* Parent container styling */
.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Make the parent container take the full viewport height */
}

.pdf-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    /* To ensure the background is white for better readability */
}

/* Adjustments to reduce white space between pages */
.pdf-container .react-pdf__Page {
    margin-bottom: 0 !important;
    padding: 2rem;
    /* Remove bottom margin */
}

/* Preventing text selection */
.pdf-container {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

/* Preventing drag and drop */
.pdf-container img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

/* Media queries for responsiveness */

/* For tablets (portrait and landscape) */
@media (max-width: 1224px) {
    .pdf-container {
        width: 90%;
        height: 90%;
        padding: 1rem;
    }

    .pdf-container .react-pdf__Page {
        padding: 1rem;
    }
}

/* For mobile phones (portrait and landscape) */
@media (max-width: 768px) {
    .pdf-container {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
    }

    .pdf-container .react-pdf__Page {
        padding: 0.5rem;
    }
}
